import React, { Suspense, lazy } from 'react';
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from 'components/layout'
const Cookies = lazy(() => import('sections/hero/Cookies.js'));
const PoliticaCookies = lazy(() => import('sections/services/PoliticaCookies.js'));
const Llamenos = lazy(() => import('sections/hero/Llamenos.js'));
const ContactCreative = lazy(() => import('sections/contact/ContactCreative.js'));
const Footer = lazy(() => import('sections/services/Footer.js'));

class Index extends React.Component {

  render() {


    return (
      <div>
        <Helmet>
          <title>Política de Cookies: Información legal y privacidad</title>
          <meta name="description" content="Descubre nuestra política de cookies y privacidad. Información legal sobre cómo utilizamos cookies para mejorar tu experiencia en nuestro sitio web." />
        </Helmet>
        <Layout>
          <Suspense fallback={<div>Cargando...</div>}>
            <Cookies />
            <PoliticaCookies />
            <Llamenos />
            <ContactCreative />
            <Footer />
          </Suspense>
        </Layout>
      </div>
    )
  }
}

export default Index

export const creativeVideoTwoQuery = graphql`
  query {
    site {
      meta: siteMetadata {
        title
        description
      }
    }
  }
`